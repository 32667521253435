<template>
  <v-card v-bind="$attrs" width="100%" height="100%" min-height="300" tile>
    <v-overlay
      :style="{ 'min-height': '300px', height: '100%;' }"
      absolute
      opacity="1"
      color="blue"
    >
      <v-alert type="info">{{ label }}</v-alert>
    </v-overlay>
  </v-card>
</template>

<script>
export default {
  name: "no_data_to_show",
  props: {
    label: {
      type: String,
      default: "No Records To Show"
    }
  }
};
</script>

<style scoped></style>
